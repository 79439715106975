
export default {
  name: `SignatureExampleHint`,
  props: {
    imageUrl: {
      type: String,
      required: true
    },
    isGood: {
      type: Boolean,
      default: true
    },
    criteria: {
      type: Object,
      required: true
    }
  },
  computed: {
    overlayIcon() {
      return this.isGood ? `mdi-check-circle` : `mdi-close-circle`;
    },
    overlayIconClass() {
      return this.isGood ? `overlay-icon good` : `overlay-icon bad`;
    }
  }
};
